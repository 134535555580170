import fr from "date-fns/locale/fr"
import type { FC, FormEvent } from "react"
import { useMemo, useState } from "react"
import DatePicker from "react-datepicker"
import styled, { css } from "styled-components"

import type {
  DtoDraftExternalExpositionForm,
  DtoDraftInternalExpositionForm,
  DtoExternalExposition,
  DtoExternalExpositionForm,
  DtoInternalExposition,
  DtoInternalExpositionForm,
} from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import { useGetAllCountries } from "../../../services/useQueries"
import {
  CEButton,
  CEHtmlInput,
  CEIconTooltipCode,
  CEInput,
  CETextPoppins,
  CELabel,
} from "../../ui"
import { CEMaps, defaultCenter, SuggestInfo } from "../../ui/CEMap"
import "react-datepicker/dist/react-datepicker.css"
import { CESuggestNewCountryModal } from "../../ui/CESuggestNewCountryModal"

export const InputSectionContainer = styled.div`
  margin-bottom: 14px;
`

export const InputSectionRowContainer = styled.div`
  margin-bottom: 44px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
`

export interface InputContainerProps {
  indent?: number
  center?: boolean
  column?: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  padding-left: ${(props) => (props.indent ? props.indent * 16 : 0)}px;
`

export const LabelWithIconContainer = styled.div`
  display: flex;
  align-items: center;
`

const DatePickerContainer = styled.div`
  margin-right: 8px;
`

export const underlinedLabelStyles = css`
  border-bottom: 1px solid black;
`

const ContentLabelContainer = styled.div`
  margin-bottom: 8px;
`

const PreviewContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  margin-left: 24px;
`
export const ButtonsContainer = styled.div<{ justifyContentEnd?: boolean }>`
  display: flex;
  gap: 10px;
  justify-content: ${(props) =>
    props.justifyContentEnd ? "flex-end" : "flex-start"};
  margin-bottom: 16px;
`

export const ButtonContainer = styled.div`
  margin-right: 8px;
  margin-left: 8px;
`
export const CESelect = styled.select`
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 10px;
  padding: 10px;
`

const FACEBOOK_BROWSER_URL_PREFIX = "https://www.facebook.com/"
const FACEBOOK_APP_URL_PREFIX =
  "fb://facewebmodal/f?href=https://www.facebook.com/"
const TWITTER_BROWSER_URL_PREFIX = "https://twitter.com/"
const TWITTER_APP_URL_PREFIX = "twitter://user?screen_name="
const INSTAGRAM_BROWSER_URL_PREFIX = "https://www.instagram.com/"
const INSTAGRAM_APP_URL_PREFIX = "instagram://user?username="
const TIKTOK_BROWSER_URL_PREFIX = "https://www.tiktok.com/@"
const TIKTOK_APP_URL_PREFIX = "https://www.tiktok.com/@"

export const InternalExpoGeneralForm: FC<{
  exposition: DtoInternalExposition
  onExpoSave: (
    exposition: DtoDraftInternalExpositionForm | DtoInternalExpositionForm
  ) => void
}> = ({ exposition, onExpoSave }) => {
  const s = strings

  const { data: countries } = useGetAllCountries()

  const [internalExpo, setInternalExpo] =
    useState<DtoDraftInternalExpositionForm>({
      id: exposition.id,
      address: exposition.address,
      endDate: exposition.endDate ? new Date(exposition.endDate) : undefined,
      startDate: exposition.startDate
        ? new Date(exposition.startDate)
        : undefined,
      website: exposition.website || { uri: undefined, title: undefined },
      title: exposition.title,
      country: exposition.country?.id,
      usefulInformations: {
        ...exposition.usefulInformations,
        feedbackUrl:
          exposition.usefulInformations?.feedbackUrl !== ""
            ? exposition.usefulInformations?.feedbackUrl
            : undefined,
        hashTag:
          exposition.usefulInformations?.hashTag !== ""
            ? exposition.usefulInformations?.hashTag
            : undefined,
        purchaseProcessUrl:
          exposition.usefulInformations?.purchaseProcessUrl !== ""
            ? exposition.usefulInformations?.purchaseProcessUrl
            : undefined,
      },
      phoneNumber: exposition.phoneNumber ?? undefined,
      longitude: exposition.longitude ?? undefined,
      latitude: exposition.latitude ?? undefined,
      playlistUrl: exposition.playlistUrl,
      directors: exposition.directors,
      additionalInformations: exposition.additionalInformations,
    })

  const [suggestNewCountry, setSuggestNewCountry] = useState<
    string | undefined
  >(undefined)

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onExpoSave(internalExpo)
  }
  const centerMap = useMemo(
    () =>
      internalExpo.latitude && internalExpo.longitude
        ? {
            lat: internalExpo.latitude,
            lng: internalExpo.longitude,
          }
        : defaultCenter,
    [internalExpo.latitude, internalExpo.longitude]
  )

  const handleSuggest = (suggestInfo: SuggestInfo) => {
    const website =
      suggestInfo.website.uri !== "" ? suggestInfo.website.uri : undefined
    setInternalExpo((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        location:
          suggestInfo.address.location !== ""
            ? suggestInfo.address.location
            : undefined,
        streetNumber:
          suggestInfo.address.streetNumber !== ""
            ? suggestInfo.address.streetNumber
            : undefined,
        streetName: suggestInfo.address.streetName ?? undefined,
        city:
          suggestInfo.address.city !== ""
            ? suggestInfo.address.city
            : undefined,
        zipCode:
          suggestInfo.address.zipCode !== ""
            ? suggestInfo.address.zipCode
            : undefined,
        country: undefined,
      },
      latitude: suggestInfo.latitude
        ? parseFloat(suggestInfo.latitude)
        : undefined,
      longitude: suggestInfo.longitude
        ? parseFloat(suggestInfo.longitude)
        : undefined,
      phoneNumber:
        suggestInfo.phoneNumber !== "" ? suggestInfo.phoneNumber : undefined,
      website: website
        ? {
            uri: {
              ...prev.website?.uri,
              fr: website,
            },
            title: {
              ...prev.website?.title,
              fr: website,
            },
          }
        : undefined,
      country: undefined,
    }))

    const country = countries?.find(
      (c) => c.code === suggestInfo.address.country
    )

    if (country) {
      setInternalExpo((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          country: country.label,
        },
        country: country.id,
      }))
    } else {
      setSuggestNewCountry(suggestInfo.address.country)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabGeneralForm.titleLabel}
              htmlFor="titleFrInput"
              styles={underlinedLabelStyles}
            />
          </InputContainer>
          {exposition.languages.map((language) => (
            <InputContainer indent={1} key={language.id}>
              <LabelWithIconContainer>
                <CEIconTooltipCode tags={["br"]} id="titleEnTooltip" />
                <CELabel title={language.label} />
              </LabelWithIconContainer>
              <CEInput
                placeholder={language.label}
                value={internalExpo.title[language.code] ?? ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    title: {
                      ...prev.title,
                      [language.code]: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
          ))}
        </InputSectionContainer>
        <InputSectionContainer>
          <InputContainer center>
            <CELabel title={s.TabGeneralForm.dateFrom} htmlFor="titleInput" />
            <DatePickerContainer>
              <DatePicker
                selected={internalExpo.startDate}
                onChange={(date: Date | null) =>
                  date
                    ? setInternalExpo((prevState) => ({
                        ...prevState,
                        startDate: date,
                      }))
                    : {}
                }
                locale={fr}
                dateFormat="dd/MM/yyyy"
              />
            </DatePickerContainer>
            <CELabel title={s.TabGeneralForm.dateTo} htmlFor="titleInput" />
            <DatePickerContainer>
              <DatePicker
                selected={internalExpo.endDate ?? undefined} // Force undefined si endDate est null
                onChange={(date: Date | null) =>
                  setInternalExpo((prevState) => ({
                    ...prevState,
                    endDate: date || undefined, // Remplace null par undefined
                  }))
                }
                locale={fr}
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </DatePickerContainer>
          </InputContainer>
        </InputSectionContainer>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabGeneralForm.directorsLabel}
              htmlFor="titleInput"
              styles={underlinedLabelStyles}
            />
          </InputContainer>
          {exposition.languages.map((language) => (
            <InputContainer key={language.id} indent={1}>
              <CELabel title={language.label} htmlFor="directorsFrInput" />
              <CEInput
                id="directorsFrInput"
                placeholder={language.label}
                value={internalExpo.directors?.[language.code] || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    directors: {
                      ...prev.directors,
                      [language.code]: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
          ))}
        </InputSectionContainer>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabGeneralForm.playlistUrlLabel}
              htmlFor="playlistUrlInput"
            />
            <CEInput
              id="playlistUrlInput"
              placeholder={s.TabGeneralForm.playlistUrlPlaceholder}
              value={internalExpo.playlistUrl || ""}
              onChange={(value) =>
                setInternalExpo((prev) => ({
                  ...prev,
                  playlistUrl: value !== "" ? value : undefined,
                }))
              }
            />
          </InputContainer>
        </InputSectionContainer>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabGeneralForm.feedbackUrlLabel}
              htmlFor="feedbackUrlInput"
            />
            <CEInput
              id="feedbackUrlInput"
              placeholder={s.TabGeneralForm.feedbackUrlPlaceholder}
              value={internalExpo.usefulInformations?.feedbackUrl || ""}
              onChange={(value) =>
                setInternalExpo((prev) => ({
                  ...prev,
                  usefulInformations: {
                    ...prev.usefulInformations,
                    feedbackUrl: value !== "" ? value : undefined,
                  },
                }))
              }
            />
          </InputContainer>
        </InputSectionContainer>
        <InputSectionRowContainer>
          <div style={{ width: " 100%" }}>
            <InputContainer>
              <CELabel
                title={s.TabGeneralForm.locationLabel}
                htmlFor="locationNameInput"
                styles={underlinedLabelStyles}
              />
              <CETextPoppins fontType="LightItalic">
                {s.TabGeneralForm.autocompleteInfo}
              </CETextPoppins>
            </InputContainer>

            <InputContainer indent={1}>
              <CELabel
                title={s.TabGeneralForm.locationNameLabel}
                htmlFor="locationNameInput"
              />
              <CEInput
                id="locationNameInput"
                placeholder={s.TabGeneralForm.locationNamePlaceholder}
                value={internalExpo.address?.location || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      location: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>

            <InputContainer indent={1}>
              <CELabel
                title={s.TabGeneralForm.addressLabel}
                htmlFor="streetNumberInput"
                styles={underlinedLabelStyles}
              />
            </InputContainer>
            <InputContainer indent={2}>
              <CELabel
                title={s.TabGeneralForm.streetNumberLabel}
                htmlFor="streetNumberInput"
              />
              <CEInput
                id="streetNumberInput"
                placeholder={s.TabGeneralForm.streetNumberPlaceholder}
                value={internalExpo.address?.streetNumber || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      streetNumber: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
            <InputContainer indent={2}>
              <CELabel
                title={s.TabGeneralForm.streetNameLabel}
                htmlFor="streetNameInput"
              />
              <CEInput
                id="streetNameInput"
                placeholder={s.TabGeneralForm.streetNamePlaceholder}
                value={internalExpo.address?.streetName || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      streetName: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
            <InputContainer indent={2}>
              <CELabel title={s.TabGeneralForm.cityLabel} htmlFor="cityInput" />
              <CEInput
                id="cityInput"
                placeholder={s.TabGeneralForm.cityPlaceholder}
                value={internalExpo.address?.city || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      city: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
            <InputContainer indent={2}>
              <CELabel
                title={s.TabGeneralForm.zipCodeLabel}
                htmlFor="zipCodeInput"
              />
              <CEInput
                id="zipCodeInput"
                placeholder={s.TabGeneralForm.zipCodePlaceholder}
                value={internalExpo.address?.zipCode || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      zipCode: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>

            <InputContainer indent={2} style={{ alignItems: "baseline" }}>
              <CELabel
                title={s.TabGeneralForm.countryLabel}
                htmlFor="countrySelect"
              />
              <CESelect
                id="countrySelect"
                value={internalExpo.country ?? "none"}
                onChange={(e) => {
                  setInternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      country: countries?.find((c) => c.id === e.target.value)
                        ?.label,
                    },
                    country:
                      e.target.value === "none" ? undefined : e.target.value,
                  }))
                }}
              >
                <option
                  label="selectionnez un pays"
                  value={"none"}
                  disabled={!!internalExpo.country}
                />
                {countries?.map((c) => (
                  <option key={c.id} label={c.label} value={c.id} />
                ))}
              </CESelect>
            </InputContainer>
            <InputContainer indent={1}>
              <CELabel
                title={s.TabGeneralForm.latitudeLabel}
                htmlFor="latitudeInput"
              />
              <CEInput
                id="latitudeInput"
                placeholder={s.TabGeneralForm.latitudePlaceholder}
                value={internalExpo.latitude?.toString() || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    latitude: !isNaN(parseFloat(value))
                      ? parseFloat(value)
                      : undefined,
                  }))
                }
                type="number"
              />
            </InputContainer>

            <InputContainer indent={1}>
              <CELabel
                title={s.TabGeneralForm.longitudeLabel}
                htmlFor="longitudeInput"
              />
              <CEInput
                id="longitudeInput"
                placeholder={s.TabGeneralForm.longitudePlaceholder}
                value={internalExpo.longitude?.toString() || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    longitude: !isNaN(parseFloat(value))
                      ? parseFloat(value)
                      : undefined,
                  }))
                }
                type="number"
              />
            </InputContainer>

            <InputContainer indent={1}>
              <CELabel
                title={s.TabGeneralForm.phoneNumberLabel}
                htmlFor="phoneNumberInput"
              />
              <CEInput
                id="phoneNumberInput"
                placeholder={s.TabGeneralForm.phoneNumberPlaceholder}
                value={internalExpo.phoneNumber || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    phoneNumber: value !== "" ? value : undefined,
                  }))
                }
              />
            </InputContainer>
          </div>
          <PreviewContainer>
            <CEMaps onChange={handleSuggest} position={centerMap} />
          </PreviewContainer>
        </InputSectionRowContainer>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabGeneralForm.websiteLabel}
              htmlFor="websiteUrlFrInput"
              styles={underlinedLabelStyles}
            />
          </InputContainer>
          <InputContainer indent={1}>
            <CELabel
              title={s.TabGeneralForm.websiteUrlLabel}
              htmlFor="websiteUrlFrInput"
              styles={underlinedLabelStyles}
            />
          </InputContainer>

          {exposition.languages.map((language) => (
            <InputContainer indent={2} key={language.id}>
              <CELabel title={language.label} />
              <CEInput
                placeholder={s.TabGeneralForm.websiteUrlFrPlaceholder}
                value={internalExpo.website?.uri?.[language.code] || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    website: {
                      ...prev.website,
                      uri: {
                        ...prev.website?.uri,
                        [language.code]: value !== "" ? value : undefined,
                      },
                    },
                  }))
                }
              />
            </InputContainer>
          ))}

          <InputContainer indent={1}>
            <CELabel
              title={s.TabGeneralForm.websiteDisplayLabel}
              styles={underlinedLabelStyles}
            />
          </InputContainer>

          {exposition.languages.map((language) => (
            <InputContainer indent={2} key={language.id}>
              <CELabel title={language.label} />
              <CEInput
                placeholder={language.label}
                value={internalExpo.website?.title?.[language.code] || ""}
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    website: {
                      ...prev.website,
                      title: {
                        ...prev.website?.title,
                        [language.code]: value !== "" ? value : undefined,
                      },
                    },
                  }))
                }
              />
            </InputContainer>
          ))}
        </InputSectionContainer>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabGeneralForm.scheduleLabel}
              htmlFor="scheduleFr"
              styles={underlinedLabelStyles}
            />
          </InputContainer>

          {exposition.languages.map((language) => (
            <InputContainer key={language.id} indent={1}>
              <LabelWithIconContainer>
                <CEIconTooltipCode tags={["br"]} id="titleEnTooltip" />
                <CELabel title={language.label} htmlFor="scheduleFr" />
              </LabelWithIconContainer>
              <CEInput
                id="scheduleFr"
                placeholder={language.label}
                value={
                  internalExpo.usefulInformations?.schedule?.[language.code] ||
                  ""
                }
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    usefulInformations: {
                      ...prev.usefulInformations,
                      schedule: {
                        ...prev.usefulInformations?.schedule,
                        [language.code]: value !== "" ? value : undefined,
                      },
                    },
                  }))
                }
              />
            </InputContainer>
          ))}
          <InputContainer>
            <CELabel
              title={s.TabGeneralForm.scheduleComplementLabel}
              htmlFor="scheduleComplementFr"
              styles={underlinedLabelStyles}
            />
          </InputContainer>
          {exposition.languages.map((language) => (
            <InputContainer key={language.id}>
              <CELabel title={language.label} htmlFor="scheduleComplementFr" />
              <CEInput
                id="scheduleComplementFr"
                placeholder={language.label}
                value={
                  internalExpo.usefulInformations?.scheduleComplement?.[
                    language.code
                  ] || ""
                }
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    usefulInformations: {
                      ...prev.usefulInformations,
                      scheduleComplement: {
                        ...prev.usefulInformations?.scheduleComplement,
                        [language.code]: value !== "" ? value : undefined,
                      },
                    },
                  }))
                }
              />
            </InputContainer>
          ))}
        </InputSectionContainer>

        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabMediasForm.socialTitle}
              htmlFor="facebookUsername"
              styles={underlinedLabelStyles}
            />
          </InputContainer>

          <InputContainer indent={1}>
            <CELabel
              title={s.TabMediasForm.facebookLabel}
              htmlFor="facebookUsername"
            />
            <CETextPoppins>{FACEBOOK_BROWSER_URL_PREFIX}</CETextPoppins>
            <CEInput
              id="facebookUsername"
              placeholder={s.TabMediasForm.facebookPlaceholder}
              value={
                internalExpo.usefulInformations?.socialLinks?.facebook?.browserUrl?.replace(
                  FACEBOOK_BROWSER_URL_PREFIX,
                  ""
                ) ?? ""
              }
              short
              onChange={(value) => {
                setInternalExpo((prev) => ({
                  ...prev,
                  usefulInformations: {
                    ...prev?.usefulInformations,
                    socialLinks: {
                      ...prev.usefulInformations?.socialLinks,
                      facebook: {
                        appUrl: `${FACEBOOK_APP_URL_PREFIX}${value}`,
                        browserUrl: `${FACEBOOK_BROWSER_URL_PREFIX}${value}`,
                      },
                    },
                  },
                }))
              }}
            />
          </InputContainer>

          <InputContainer indent={1}>
            <CELabel
              title={s.TabMediasForm.twitterLabel}
              htmlFor="twitterUsername"
            />
            <CETextPoppins>{TWITTER_BROWSER_URL_PREFIX}</CETextPoppins>
            <CEInput
              id="twitterUsername"
              placeholder={s.TabMediasForm.twitterPlaceholder}
              value={
                internalExpo.usefulInformations?.socialLinks?.twitter?.browserUrl?.replace(
                  TWITTER_BROWSER_URL_PREFIX,
                  ""
                ) ?? ""
              }
              short
              onChange={(value) => {
                setInternalExpo((prev) => ({
                  ...prev,
                  usefulInformations: {
                    ...prev?.usefulInformations,
                    socialLinks: {
                      ...prev.usefulInformations?.socialLinks,
                      twitter: {
                        appUrl: `${TWITTER_APP_URL_PREFIX}${value}`,
                        browserUrl: `${TWITTER_BROWSER_URL_PREFIX}${value}`,
                      },
                    },
                  },
                }))
              }}
            />
          </InputContainer>

          <InputContainer indent={1}>
            <CELabel
              title={s.TabMediasForm.instagramLabel}
              htmlFor="instagramUsername"
            />
            <CETextPoppins>{INSTAGRAM_BROWSER_URL_PREFIX}</CETextPoppins>
            <CEInput
              id="instagramUsername"
              placeholder={s.TabMediasForm.instagramPlaceholder}
              value={
                internalExpo.usefulInformations?.socialLinks?.instagram?.browserUrl?.replace(
                  INSTAGRAM_BROWSER_URL_PREFIX,
                  ""
                ) ?? ""
              }
              short
              onChange={(value) => {
                setInternalExpo((prev) => ({
                  ...prev,
                  usefulInformations: {
                    ...prev?.usefulInformations,
                    socialLinks: {
                      ...prev.usefulInformations?.socialLinks,
                      instagram: {
                        appUrl: `${INSTAGRAM_APP_URL_PREFIX}${value}`,
                        browserUrl: `${INSTAGRAM_BROWSER_URL_PREFIX}${value}`,
                      },
                    },
                  },
                }))
              }}
            />
          </InputContainer>

          <InputContainer indent={1}>
            <CELabel
              title={s.TabMediasForm.tiktokLabel}
              htmlFor="tiktokUsername"
            />
            <CETextPoppins>{TIKTOK_BROWSER_URL_PREFIX}</CETextPoppins>
            <CEInput
              id="tiktokUsername"
              placeholder={s.TabMediasForm.tiktokPlaceholder}
              value={
                internalExpo.usefulInformations?.socialLinks?.tiktok?.browserUrl?.replace(
                  TIKTOK_BROWSER_URL_PREFIX,
                  ""
                ) ?? ""
              }
              short
              onChange={(value) => {
                setInternalExpo((prev) => ({
                  ...prev,
                  usefulInformations: {
                    ...prev?.usefulInformations,
                    socialLinks: {
                      ...prev.usefulInformations?.socialLinks,
                      tiktok: {
                        appUrl: `${TIKTOK_APP_URL_PREFIX}${value}`,
                        browserUrl: `${TIKTOK_BROWSER_URL_PREFIX}${value}`,
                      },
                    },
                  },
                }))
              }}
            />
          </InputContainer>

          <InputContainer indent={1}>
            <CELabel title={s.TabMediasForm.hashtagLabel} htmlFor="hashtag" />
            <CEInput
              id="hashtag"
              placeholder={s.TabMediasForm.hashtagPlaceholder}
              value={internalExpo.usefulInformations?.hashTag ?? ""}
              short
              onChange={(value) => {
                setInternalExpo((prev) => ({
                  ...prev,
                  usefulInformations: {
                    ...prev?.usefulInformations,
                    hashTag: value !== "" ? value : undefined,
                  },
                }))
              }}
            />
          </InputContainer>
        </InputSectionContainer>

        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabMediasForm.purchaseProcessUrlLabel}
              htmlFor="purchaseProcessUrl"
            />
            <CEInput
              id="purchaseProcessUrl"
              placeholder={s.TabMediasForm.purchaseProcessUrlPlaceholder}
              value={internalExpo.usefulInformations?.purchaseProcessUrl ?? ""}
              onChange={(value) => {
                setInternalExpo((prev) => ({
                  ...prev,
                  usefulInformations: {
                    ...prev?.usefulInformations,
                    purchaseProcessUrl: value !== "" ? value : undefined,
                  },
                }))
              }}
            />
          </InputContainer>
        </InputSectionContainer>

        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.TabGeneralForm.additionalInformationsLabel}
              htmlFor="additionalInformationsFr"
              styles={underlinedLabelStyles}
            />
          </InputContainer>

          {exposition.languages.map((language) => (
            <InputContainer key={language.id} indent={1} column>
              <ContentLabelContainer>
                <LabelWithIconContainer>
                  <CEIconTooltipCode tags={["br"]} id="titleEnTooltip" />
                  <CELabel
                    title={language.label}
                    htmlFor="additionalInformationsFrInput"
                  />
                </LabelWithIconContainer>
              </ContentLabelContainer>

              <CEHtmlInput
                id="additionalInformationsFrInput"
                placeholder={language.label}
                value={
                  internalExpo.additionalInformations?.[language.code] || ""
                }
                onChange={(value) =>
                  setInternalExpo((prev) => ({
                    ...prev,
                    additionalInformations: {
                      ...prev.additionalInformations,
                      [language.code]: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
          ))}
        </InputSectionContainer>

        <ButtonsContainer justifyContentEnd>
          <ButtonContainer>
            <CEButton title={strings.button.save} type="submit" />
          </ButtonContainer>
        </ButtonsContainer>
      </form>
      {!!suggestNewCountry && (
        <CESuggestNewCountryModal
          onClose={() => setSuggestNewCountry(undefined)}
          onConfirm={(newCountry) => {
            setInternalExpo((prev) => ({
              ...prev,
              address: {
                ...prev.address,
                country: newCountry.label,
              },
              country: newCountry.id,
            }))
            setSuggestNewCountry(undefined)
          }}
          countryCode={suggestNewCountry}
        />
      )}
    </>
  )
}

export const ExternalExpoGeneralForm: FC<{
  exposition: DtoExternalExposition
  onExpoSave: (
    exposition: DtoDraftExternalExpositionForm | DtoExternalExpositionForm
  ) => void
}> = ({ exposition, onExpoSave }) => {
  const s = strings.TabGeneralForm

  const { data: countries } = useGetAllCountries()
  const [suggestNewCountry, setSuggestNewCountry] = useState<
    string | undefined
  >(undefined)

  const [externalExpo, setExternalExpo] =
    useState<DtoDraftExternalExpositionForm>({
      id: exposition.id,
      address: exposition.address,
      endDate: exposition.endDate ? new Date(exposition.endDate) : new Date(),
      startDate: exposition.startDate
        ? new Date(exposition.startDate)
        : new Date(),
      website: exposition.website || { uri: undefined, title: undefined },
      title: exposition.title,
      country: exposition.country?.id,
    })

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onExpoSave(externalExpo)
  }
  const handleSuggest = (suggestInfo: SuggestInfo) => {
    const website =
      suggestInfo.website.uri !== "" ? suggestInfo.website.uri : undefined
    setExternalExpo((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        location:
          suggestInfo.address.location !== ""
            ? suggestInfo.address.location
            : undefined,
        streetNumber:
          suggestInfo.address.streetNumber !== ""
            ? suggestInfo.address.streetNumber
            : undefined,
        streetName: suggestInfo.address.streetName ?? undefined,
        city:
          suggestInfo.address.city !== ""
            ? suggestInfo.address.city
            : undefined,
        zipCode:
          suggestInfo.address.zipCode !== ""
            ? suggestInfo.address.zipCode
            : undefined,
        country: undefined,
      },
      website: website
        ? {
            uri: {
              ...prev.website?.uri,
              fr: website,
            },
            title: {
              ...prev.website?.title,
              fr: website,
            },
          }
        : undefined,
      country: undefined,
    }))

    const country = countries?.find(
      (c) => c.code === suggestInfo.address.country
    )

    if (country) {
      setExternalExpo((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          country: country.label,
        },
        country: country.id,
      }))
    } else {
      setSuggestNewCountry(suggestInfo.address.country)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={s.titleLabel}
              htmlFor="titleFrInput"
              styles={underlinedLabelStyles}
            />
          </InputContainer>
          {exposition.languages.map((language) => (
            <InputContainer indent={1} key={language.id}>
              <LabelWithIconContainer>
                <CEIconTooltipCode tags={["br"]} id="titleEnTooltip" />
                <CELabel title={language.label} />
              </LabelWithIconContainer>
              <CEInput
                placeholder={language.label}
                value={externalExpo.title[language.code] ?? ""}
                onChange={(value) =>
                  setExternalExpo((prev) => ({
                    ...prev,
                    title: {
                      ...prev.title,
                      [language.code]: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
          ))}
        </InputSectionContainer>
        <InputSectionContainer>
          <InputContainer center>
            <CELabel title={s.dateFrom} htmlFor="titleInput" />
            <DatePickerContainer>
              <DatePicker
                selected={externalExpo.startDate}
                onChange={(date: Date | null) =>
                  date
                    ? setExternalExpo((prevState) => ({
                        ...prevState,
                        startDate: date,
                      }))
                    : {}
                }
                locale={fr}
                dateFormat="dd/MM/yyyy"
              />
            </DatePickerContainer>
            <CELabel title={s.dateTo} htmlFor="titleInput" />
            <DatePickerContainer>
              <DatePicker
                selected={externalExpo.endDate}
                onChange={(date: Date | null) =>
                  date
                    ? setExternalExpo((prevState) => ({
                        ...prevState,
                        endDate: date,
                      }))
                    : {}
                }
                locale={fr}
                dateFormat="dd/MM/yyyy"
              />
            </DatePickerContainer>
          </InputContainer>
        </InputSectionContainer>
        <InputSectionRowContainer>
          <div style={{ width: " 100%" }}>
            <InputContainer>
              <CELabel
                title={s.locationLabel}
                htmlFor="locationNameInput"
                styles={underlinedLabelStyles}
              />
              <CETextPoppins fontType="LightItalic">
                {s.autocompleteInfo}
              </CETextPoppins>
            </InputContainer>

            <InputContainer indent={1}>
              <CELabel
                title={s.locationNameLabel}
                htmlFor="locationNameInput"
              />
              <CEInput
                id="locationNameInput"
                placeholder={s.locationNamePlaceholder}
                value={externalExpo.address?.location || ""}
                onChange={(value) =>
                  setExternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      location: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>

            <InputContainer indent={1}>
              <CELabel
                title={s.addressLabel}
                htmlFor="streetNumberInput"
                styles={underlinedLabelStyles}
              />
            </InputContainer>
            <InputContainer indent={2}>
              <CELabel
                title={s.streetNumberLabel}
                htmlFor="streetNumberInput"
              />
              <CEInput
                id="streetNumberInput"
                placeholder={s.streetNumberPlaceholder}
                value={externalExpo.address?.streetNumber || ""}
                onChange={(value) =>
                  setExternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      streetNumber: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
            <InputContainer indent={2}>
              <CELabel title={s.streetNameLabel} htmlFor="streetNameInput" />
              <CEInput
                id="streetNameInput"
                placeholder={s.streetNamePlaceholder}
                value={externalExpo.address?.streetName || ""}
                onChange={(value) =>
                  setExternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      streetName: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
            <InputContainer indent={2}>
              <CELabel title={s.cityLabel} htmlFor="cityInput" />
              <CEInput
                id="cityInput"
                placeholder={s.cityPlaceholder}
                value={externalExpo.address?.city || ""}
                onChange={(value) =>
                  setExternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      city: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
            <InputContainer indent={2}>
              <CELabel title={s.zipCodeLabel} htmlFor="zipCodeInput" />
              <CEInput
                id="zipCodeInput"
                placeholder={s.zipCodePlaceholder}
                value={externalExpo.address?.zipCode || ""}
                onChange={(value) =>
                  setExternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      zipCode: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>

            <InputContainer indent={2} style={{ alignItems: "baseline" }}>
              <CELabel title={s.countryLabel} htmlFor="countrySelect" />
              <CESelect
                id="countrySelect"
                value={externalExpo.country}
                onChange={(e) => {
                  setExternalExpo((prev) => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      country: countries?.find((c) => c.id === e.target.value)
                        ?.label,
                    },
                    country: e.target.value,
                  }))
                }}
              >
                <option
                  label="selectionnez un pays"
                  value={undefined}
                  disabled={!!externalExpo.country}
                />
                {countries?.map((c) => (
                  <option key={c.id} label={c.label} value={c.id} />
                ))}
              </CESelect>
            </InputContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.websiteLabel}
                  htmlFor="websiteUrlFrInput"
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              <InputContainer indent={1}>
                <CELabel
                  title={s.websiteUrlLabel}
                  htmlFor="websiteUrlFrInput"
                  styles={underlinedLabelStyles}
                />
              </InputContainer>

              {exposition.languages.map((language) => (
                <InputContainer indent={2} key={language.id}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={s.websiteUrlFrPlaceholder}
                    value={externalExpo.website?.uri?.[language.code] || ""}
                    onChange={(value) =>
                      setExternalExpo((prev) => ({
                        ...prev,
                        website: {
                          ...prev.website,
                          uri: {
                            ...prev.website?.uri,
                            [language.code]: value !== "" ? value : undefined,
                          },
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}

              <InputContainer indent={1}>
                <CELabel
                  title={s.websiteDisplayLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>

              {exposition.languages.map((language) => (
                <InputContainer indent={2} key={language.id}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={language.label}
                    value={externalExpo.website?.title?.[language.code] || ""}
                    onChange={(value) =>
                      setExternalExpo((prev) => ({
                        ...prev,
                        website: {
                          ...prev.website,
                          title: {
                            ...prev.website?.title,
                            [language.code]: value !== "" ? value : undefined,
                          },
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
          </div>
          <PreviewContainer>
            <CEMaps onChange={handleSuggest} position={defaultCenter} />
          </PreviewContainer>
        </InputSectionRowContainer>
        <ButtonsContainer justifyContentEnd>
          <ButtonContainer>
            <CEButton title={strings.button.save} type="submit" />
          </ButtonContainer>
        </ButtonsContainer>
      </form>
      {!!suggestNewCountry && (
        <CESuggestNewCountryModal
          onClose={() => setSuggestNewCountry(undefined)}
          onConfirm={(newCountry) => {
            setExternalExpo((prev) => ({
              ...prev,
              address: {
                ...prev.address,
                country: newCountry.label,
              },
              country: newCountry.id,
            }))
            setSuggestNewCountry(undefined)
          }}
          countryCode={suggestNewCountry}
        />
      )}
    </>
  )
}
