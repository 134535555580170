import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from "@hello-pangea/dnd"
import { useEffect, useRef, useState, type FC } from "react"
import { css } from "styled-components"

import type {
  DtoExposition,
  DtoFullVideos,
} from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import { useOrderChanged } from "../../../hooks/useOrderChanged"
import {
  useGetFullVideos,
  useUpdateVideoTitle,
  useUploadFile,
} from "../../../services/useQueries"
import styled from "../../../styled-components"
import { CEButton, CEDropzone, CETextPoppins } from "../../ui"
import { CEFullVideo } from "../../ui/CEFullVideo"
import {
  ButtonContainer,
  ButtonsContainer,
  InputSectionRowContainer,
} from "../TabGeneral/TabGeneralForm"

export const TabMedias: FC<{
  exposition: DtoExposition
  isLoading: boolean
}> = ({ exposition, isLoading }) => {
  const s = strings.TabMediasForm
  const { data: fullVideos } = useGetFullVideos(exposition.id)
  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile()
  const { dispatch, isOrderChanged } = useOrderChanged()

  const [fullVideosState, setFullVideosState] = useState<DtoFullVideos[]>([])
  const [formDataState, setFormDataState] = useState<FormData>()
  const { mutateAsync: updateVideoTitle } = useUpdateVideoTitle()

  const prevFullVideosRef: any = useRef(null)

  useEffect(() => {
    if (fullVideos !== prevFullVideosRef.current) {
      if (fullVideos) {
        const sortedVideos = [...fullVideos].sort((a, b) => a.order - b.order)
        setFullVideosState(sortedVideos)
      }

      prevFullVideosRef.current = fullVideos
    }
  }, [fullVideos])

  useEffect(() => {
    if (fullVideos) {
      for (let i = 0; i < fullVideos.length; i++) {
        if (
          fullVideosState.length &&
          fullVideosState[i]._id !== fullVideos[i]._id
        ) {
          dispatch("ORDER_CHANGED")
          break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fullVideosState])

  const handleFullVideoTitleChange = (index: number, newTitle: string) => {
    const updatedVideosState = fullVideosState.map((video, i) => {
      if (i === index) {
        return { ...video, title: newTitle, _id: video._id, order: index }
      }
      return video
    })
    setFullVideosState(updatedVideosState)
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(fullVideosState)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setFullVideosState(items)
  }

  const uploadVideo = (item: DtoFullVideos, order: string) => {
    const formData = formDataState ?? new FormData()
    if (!formData.get("title")) {
      formData.append("title", item.title)
      formData.append("order", order)
    }

    uploadFile({
      formData: formData,
      entity: {
        field: "fullVideos",
        id: exposition.id,
        type: "expositions",
      },
    })
  }

  return (
    <Root>
      <InputSectionRowContainer>
        {!exposition.isExternal && (
          <UploadContainer>
            <UploadTitleContainer>
              <CETextPoppins>{s.imageLabel}</CETextPoppins>
            </UploadTitleContainer>
            <UploadSpecificationsContainer>
              <CETextPoppins styles={uploadSpecificationStyles}>
                {s.imageSpecifications}
              </CETextPoppins>
            </UploadSpecificationsContainer>

            <UploadMediaContainer>
              <ImageDropzoneContainer>
                <CEDropzone
                  type="image"
                  entity={{
                    field: "media",
                    id: exposition.id,
                    type: "expositions",
                  }}
                  isEntityLoading={isLoading}
                  defaultFileUrl={exposition.media}
                />
              </ImageDropzoneContainer>
            </UploadMediaContainer>
          </UploadContainer>
        )}
        <UploadContainer>
          <UploadTitleContainer>
            <CETextPoppins>{s.imageThumbnailLabel}</CETextPoppins>
          </UploadTitleContainer>

          <UploadSpecificationsContainer>
            <CETextPoppins styles={uploadSpecificationStyles}>
              {s.imageThumbnailSpecifications}
            </CETextPoppins>
          </UploadSpecificationsContainer>

          <UploadMediaContainer>
            <ImageThumbnailDropzoneContainer>
              <CEDropzone
                type="image"
                entity={{
                  field: "mediaThumbnail",
                  id: exposition.id,
                  type: "expositions",
                }}
                isEntityLoading={isLoading}
                defaultFileUrl={exposition.thumbnailMedia}
              />
            </ImageThumbnailDropzoneContainer>
          </UploadMediaContainer>
        </UploadContainer>
      </InputSectionRowContainer>

      {!exposition.isExternal && (
        <>
          <InputSectionRowContainer>
            <UploadContainer>
              <UploadTitleContainer>
                <CETextPoppins>{s.fullVideoLabel}</CETextPoppins>
              </UploadTitleContainer>

              <UploadSpecificationsContainer>
                <CETextPoppins styles={uploadSpecificationStyles}>
                  {s.fullVideoSpecifications}
                </CETextPoppins>
              </UploadSpecificationsContainer>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="fullVideos" direction="horizontal">
                  {(provided) => (
                    <UploadMediaContainer
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {fullVideosState.map((item, index) => (
                        <Draggable
                          key={item._id}
                          draggableId={item._id + index.toString()}
                          index={index}
                          isDragDisabled={false}
                        >
                          {(provided) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <CEFullVideo
                                  index={index}
                                  item={item}
                                  expoId={exposition.id}
                                  fullVideos={fullVideos}
                                  formDataState={formDataState}
                                  isOrderChanged={isOrderChanged}
                                  isLoading={isUploading}
                                  handleFullVideoTitleChange={
                                    handleFullVideoTitleChange
                                  }
                                  uploadVideo={uploadVideo}
                                  setFormDataState={setFormDataState}
                                />
                              </div>
                            )
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </UploadMediaContainer>
                  )}
                </Droppable>
              </DragDropContext>
              {isOrderChanged && (
                <ButtonsContainer>
                  <ButtonContainer>
                    <CEButton
                      title={strings.button.cancel}
                      onClick={() => {
                        setFullVideosState(fullVideos ?? [])
                        dispatch("ORDER_CANCELED")
                      }}
                    />
                  </ButtonContainer>
                  <ButtonContainer>
                    <CEButton
                      title={s.edit}
                      onClick={async () => {
                        fullVideosState.map((item, index) => {
                          if (item.order !== index) {
                            updateVideoTitle({
                              videoId: item._id,
                              title: item.title,
                              order: index.toString(),
                            })
                          }
                        })

                        if (isOrderChanged) {
                          dispatch("ORDER_SAVED")
                        }
                      }}
                    />
                  </ButtonContainer>
                </ButtonsContainer>
              )}
              <CEButton
                title={s.addVideo}
                onClick={() =>
                  setFullVideosState([
                    ...fullVideosState,
                    {
                      url: "",
                      title: "",
                      _id: "",
                      order: fullVideosState.length,
                    },
                  ])
                }
              />
            </UploadContainer>
          </InputSectionRowContainer>
        </>
      )}
    </Root>
  )
}

const Root = styled.div`
  padding: ${(props) => props.theme.padding.root};
`

const UploadTitleContainer = styled.div`
  margin-bottom: 4px;
`
const UploadSpecificationsContainer = styled.div`
  margin-bottom: 8px;
`

const uploadSpecificationStyles = css`
  font-size: ${({ theme }) => theme.fontSize.caption};
`
const UploadMediaContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`
const ImageDropzoneContainer = styled.div`
  width: 240px;
  height: 360px;
`
const UploadContainer = styled.div`
  width: 100%;
`
const ImageThumbnailDropzoneContainer = styled.div`
  width: 300px;
  height: 250px;
`
